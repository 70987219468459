$(document).ready(function () {
    var duration = 500;
    $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
        $('.cRetour').fadeIn(duration);
      } else {
        $('.cRetour').fadeOut(duration);
      }
    });

    $('.cRetour').click(function(event) {
      event.preventDefault();
      $('html, body').animate({scrollTop: 0}, duration);
      return false;
    })

});
